/*=============================================================================================	
    Company    : PT Web Architect Technology - webarq.com
    Document   : Stylesheet
    Author     : Andri Samsuri
==============================================================================================*/
@import "sass-lib";
@media all and (max-width: 1350px) {   

	.wrapper {width: 960px;}    
	.btn {
		&.yell {padding: 12px 15px 8px 15px;}            
	} 
	header {   
		.top {
			height: 0;
			transition: .2s all ease-out;
			.search {display: none;}       
			.lang {display: none;}       
			/* height: 35px; 
			.search {
				padding: 10px 0 10px 20px;    
				&:before {height:15px;}  
				a {top: 0px;} 
				.search-drop {top: 45px;}   
			}
			.lang {
				span {line-height: 35px;}     
			} */
		} 
		.bot {
			padding: 0;
			.wrapper{
				display: flex;
				align-items: center;
			}
			.left_header{
				position: relative;
			}
			.right_header { 
				display: none; 
				nav {
					ul {
						li {
							margin-right: 0; margin-left: 20px; 
							&:last-child {display:none;} 
							ul {
								li {
									margin-top: 0; 
								}
							}
						}
					}
				}
			}
		}
		.togglemenu {
			display: block;
			margin: 0;
			margin-left:auto;
			height: 20px;
		}
		&.float {
			height: 80px;  
			.bot {
				.left_header {
					padding-top: 0;
					top: 0;
					a {width:300px;}
				}
				.right_header {      
					nav {
						ul {							
							li {	
								margin-top: 30px; 		 					
								&:last-child {display:inline-block;}        
								&.btn.yell {margin-top: 18px !important;}   
								ul {
									li {
										margin-top: 0; 
									}
								}   
							}
						}
					}
				}
			}
			.togglemenu {margin-top: 0;}
		}
		&.actmob{
			.togglemenu{
				margin-top: 0;
			}
		}
	}

	.home {     
		&.visimisi {        
			.bg-visi {width: 540px; height: 330px; background-size: 100% 100%;}          
			.box-visimisi {  
				bottom: 0; left:10px; padding-left: 275px; min-height: 450px;           
				.images-visi {width: 215px; height: 300px; background-size:auto 100% !important; bottom: 84px;}     
				.visi {width: 40%; margin-right: 5%;}            
				.misi {width: 45%;} 
				h3 {font-weight: bold;} 
			}  
			.tittle-sec {margin-top: 90px;}                  
		} 
		&.news-event { 
			.box-list {
				.box {
					.sub-box {
						height: 245px;
						a {
							h4 {margin: 20px 0;}    
						}
					}  
				}
			}
		}
		&.number { 
			.box-top {   
				// margin-top: -170px;   
				.box-info {
					.list-info {
						width: 24.8%; height: 150px; 
						.isi {
							padding: 20px;
							i {
								&.info {left: 20px; bottom: 20px;} 
							}
							h2 {right: 20px; bottom: 20px;}    
						} 
					}      
				}
			}
		} 
	}
	
	.top-menu {
		height: 45px; margin-top: -47px; @include boxSizing(border-box);  
		.left {
			&.breadcrumb {
				ul {
					li {
						line-height: 45px; height: 45px; margin-right: 20px;  
						&:before {left:-13px;}    
					} 
				}
			}
		}
		.right {  
			&.submenu {
				span {line-height: 45px; font-size: 12px;}  
				.ico {
					&:before {height:45px;} 
				} 	
			}
		}
	} 

	.wrap_popup { 
		.content_pop {width: 840px; margin: 17% auto;}           
	}

	.map {
		.txt-map {
			width: 310px; height: 210px; right: 60px; top: 50px;     
			h5 {margin-bottom: 10px;} 
		}     
		#idMap {height: 320px;}   
	}
	

	.slide-overview {    
		margin-left: -50px;
		.slick-slider {}  		       
		.slider-text {
			right: -70px;         
		}
	}

	.content-std {    
		.inner-content {  
			padding: 50px 97px;
			&.havebg {background-size:60% !important; background-position: 140% 5% !important;}
		}   
		h1 {
			&.left {width: 60%;}
		}       
		table { 
			tr {
				td {font-size: 14px;}   
			}
		}  
		.bot-img { 
			.lis-bot-img { 
				a { 
					h5 {font-size: 12px;}     
				}
			}
		}
		.list-issue {
			.box-issue {height: 200px;}     
		}
		.box-wrap-news {
			padding: 0 50px;
			.list-news {
				.text-news {
					h3 {margin: 7px 0 12px 0;}    
					p {margin-bottom: 15px;}    
				}
			}
		}
		.wrap-newsdetail {
			.box-newsdetail {
				.list-newsdetail {
					.text {
						h5 {font-size: 14px; line-height: 16px;}  
					}
				}
			}
		}
	}
	.sub-content {
		&.full {padding-left: 0;}
		.slide-overview {margin-left: -125px;}   
		.left-absolute {width: 60px;} 
		.btn-share {left: -50px;}
	}

	#calendar{
		.fc-widget-header{
			th { width: auto; }
		}
		td{ width: auto; }
	}
	.banner-top{
		padding: 150px 0 100px;
		height: calc(100vh - 150px);
		.wrap-box{
			height: calc(100vh - 150px);
			.box{
				max-height: 100%;
			}
			h2{
				font-size: 30px;
			}
		}
	}
	.home{
		&.what-other{
			.slider{
				.slick-arrow{
					&.slick-prev{
						left: 0;
						right: auto;
					}
					&.slick-next{
						right: 0;
						left: auto;
					}
				}
			}
		}
		&.family-slider{
			.slider{
				.slick-arrow{
					&.slick-prev{
						right: auto;
						left: 0;
					}
					&.slick-next{
						left: auto;
						right: 0;
					}
				}
			}
		}
	}	
}